import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import StartupStudioContainer from "../containers/StartupStudioContainer"

const StartUpStudioPage = props => (
  <Layout
    pageInfo={{ pageName: "Startup Studio" }}
    styleClass="home-page"
    location={props.location.pathname}
  >
    <SEO title="Startup Studio" />
    <StartupStudioContainer />
  </Layout>
)

export default StartUpStudioPage
